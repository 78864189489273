import React, { memo, useEffect, useState } from 'react';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import SocialLinksComponent from 'app/components/SocialLinksComponent';
import { setYaReachGoal } from 'app/selectors/Helpers';
import { QRCode } from 'react-qrcode-logo';
import {
	AVRORA_CINEMA_ID,
} from 'app/constants/CinemaConstants';
import { getIsKinokassa, isFondKino } from 'app/selectors/AppSelectors';
import { FooterProps } from 'app/containers/FooterContainer';
import { REFUND_LINK, isEnabledSalesRules } from 'app/constants/AppConstants';
import { useIsMobile } from 'app/hooks';
import ContactsContainer from 'app/containers/header/ContactsContainer';
import LawDocumentsLinks from '../documents/LawDocumentsLinks';
import { ILawDocumentLink, getCinemaDocumentsLinks } from '../documents/helpers';
import RefundButton from 'app/components/refund/RefundButton';
import { useSelector } from 'react-redux';

function Footer({
	cinemaID, links,
	cinema: {
		payment,
	},
	apps: {appstore, googleplay},
	socials,
	isSemiblind,
	isSeveralCinemasInCity,
	setSemiblind,
	setFontSize,
	showPopup,
}: FooterProps) {
	const isTablet = useIsMobile(true);
	const [lawDocsLinks, setLawDocsMap] = useState<ILawDocumentLink[] | null>(null);
	const isKinokassa = useSelector(getIsKinokassa);

	useEffect(() => {
		const id = isKinokassa ? Infinity : cinemaID;

		if (id) {
			setLawDocsMap(getCinemaDocumentsLinks(id))
		}
	}, [cinemaID]);

	const handleClickItemMenu = (event) => {
		const {isCustomCinemaLink, linkTitle} = event.target.dataset;

		if (isCustomCinemaLink) {
			setYaReachGoal('open-section', {section: linkTitle});
		}

		document.body.scrollTop = document.documentElement.scrollTop = 0;
	};

	const handleSemiblindClick = () => {
		localStorage.setItem('colorTheme', 'blackOnWhite');
		localStorage.setItem('showImages', 'imageOn');
		localStorage.setItem('fontSize', '16');
		localStorage.setItem('fontType', 'noSerif');
		localStorage.setItem('lineHeight', 'line_1_5');
		localStorage.setItem('letterSpacing', 'letter-spacing1');

		setSemiblind(true);
		setFontSize(16);
	};

	const renderMenuLinks = () => links.map(({href, title, isCustomCinemaLink, sub }, index) => (
		<KeydownEnterLink
			to={href}
			key={index}
			className="footer__links-item"
			data-is-custom-cinema-link={isCustomCinemaLink}
			data-link-title={sub && sub.length ? sub[0].title : title}
			onClick={handleClickItemMenu}
		>
			{title}
		</KeydownEnterLink>
	))

	const renderPaymentInfoSection = () => {
		return (
			<div className="footer__payment-cards-wrapper">
				{payment.card.map((card, index) => (
					<img
						key={index}
						alt={`${card}`}
						className="footer__badge footer__badge--payment"
						src={`/img/badge/card/${card}.svg`}
					/>
				))}
			</div>
		);
	}

	const renderAppsLinks = () => {
		const appsLinksData = [
			{
				linkId: 'appstore',
				href: appstore,
			},
			{
				linkId: 'googleplay',
				href: googleplay,
			},
		];

		return (
			<div className="footer__app-links-wrapper">
				{appsLinksData.map(({linkId, href}) => href ? (
					<KeydownEnterLink
						key={linkId}
						type="plain"
						style={{backgroundImage: `url('/img/badge/app/${linkId}.svg')`}}
						className="footer__badge footer__badge--app-link"
						href={href}
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => setYaReachGoal(`${linkId}-click`)}
					/>
				) : null)}
			</div>
		);
	}

	const renderLowDocuments = () => {
		return !isEnabledSalesRules && lawDocsLinks?.length && (
			<span className="text text--size-12 footer__docs">
				{i18n.t('YouMightKnowWith')}
				{' '}
				<LawDocumentsLinks lawDocsLinks={lawDocsLinks} classes="text--link-accent" showPopup={showPopup} />
			</span>
		)
	}

	return (
		<footer className="footer footer--new">
			<div className="footer__top">
				<div className="footer__rights">
					{isFondKino && (
						<img className="footer__logo" src="/img/logo.svg" alt={i18n.t('Logo')} />
					)}
					{!_.isEmpty(socials) && !isSeveralCinemasInCity ? (
						<SocialLinksComponent
							className="footer__social"
							linksData={socials}
							isColorIcons
						/>
					) : null}
					{renderLowDocuments()}
					{!isSemiblind && !isTablet && (
						<KeydownEnterButton
							className="footer__view-button view-button"
							onClick={handleSemiblindClick}
						>
							{i18n.t('VersionForTheVisuallyImpaired')}
						</KeydownEnterButton>
					)}
				</div>
				<div className="footer__links">
					{renderMenuLinks()}
				</div>
				<div className="footer__links">
					<div className="text text--size-14 text--gray">{i18n.t('Help')}</div>
					<RefundButton linkClassName="text text--white text--unlink"/>
				</div>
				{!isSemiblind && isTablet && (
					<KeydownEnterButton
						className="footer__view-button view-button"
						onClick={handleSemiblindClick}
					>
						{i18n.t('VersionForTheVisuallyImpaired')}
					</KeydownEnterButton>
				)}
				{isTablet && <ContactsContainer hasTitle />}
				<div className="footer__apps">
					{googleplay || appstore ? (
						<div className="footer__apps-qrcode">
							<QRCode value={`${REFUND_LINK}/redirect_to_store`} size={108} logoPadding={10} />
						</div>
					) : null}
					<span>{i18n.t('DownloadKinokassa')}</span>
					{renderAppsLinks()}
				</div>
			</div>
			<div className="footer__bottom">
				<div className="footer__copyright-wrapper text text--size-12">
					<span>
						© {new Date().getFullYear()}
						<KeydownEnterLink
							type="plain"
							href="https://kinokassa.ru/"
							target="_blank"
							className="text text--link-accent"
							rel="noopener noreferrer"
						>
							&nbsp;kinokassa.ru&nbsp;
						</KeydownEnterLink>
						| {i18n.t('AllRightsReserved')}.
					</span>
					<span>
						{`${i18n.t('DevelopedAt')} `}
						<KeydownEnterLink
							type="plain"
							className="text text--link-accent"
							href="https://kinoplan.ru/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{i18n.t('Kinoplane')}
						</KeydownEnterLink>
					</span>
				</div>
				{payment.active && renderPaymentInfoSection()}
				<div>
					{cinemaID !== AVRORA_CINEMA_ID
						? <p className="footer__ads-public-notice">
							{i18n.t('StartOfAdvertisingBlock')}.<br/>
							{i18n.t('CheckDirectionAtCinema')}.
						</p>
						: null
					}
				</div>
			</div>
		</footer>
	);
}

export default memo(Footer);
