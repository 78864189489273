import {connect} from 'react-redux';
import {
	getCinema,
	hasSelectButton,
	isMobileSelector,
	getAddresses,
	getSocials,
	getContactsPhones,
	getIsSemiblind,
	getIsCitySelectShown,
	getMenuLinks,
	getIsSeveralCinemasInCity,
	isFeedbackEnabled as getIsFeedbackEnabled,
} from 'app/selectors/AppSelectors';
import {setSemiblind, setFontSize, showPopup} from 'app/actions/AppActions';
import Header from 'app/components/HeaderComponent';
import KinokassaHeader from 'app/components/header/Header';
import MobileHeader from 'app/components/header/MobileHeader';
import { hasAdvertisementsList } from 'app/selectors/AdvertisementsSelectors';

const mapStateToProps = (state) => ({
	cinema: getCinema(state),
	menuLinks: getMenuLinks(state),
	phones: getContactsPhones(state),
	hasSelectButton: hasSelectButton(state),
	isMobile: isMobileSelector(state),
	socials: getSocials(state),
	addresses: getAddresses(state),
	isSemiblind: getIsSemiblind(state),
	isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
	isCitySelectShown: getIsCitySelectShown(state),
	isFeedbackEnabled: getIsFeedbackEnabled(state),
	hasAdvertisementsList: hasAdvertisementsList(state),
});

export default {
	Main: connect(mapStateToProps, {setSemiblind, setFontSize}, null, {pure: false})(Header),
	Kinokassa: connect(mapStateToProps, {setSemiblind, setFontSize, showPopup}, null, {pure: false})(KinokassaHeader),
	Mobile: connect(mapStateToProps)(MobileHeader),
};
