import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '181, 31, 35',
		'primary-dark': '125, 3, 8',
		'accent': '253, 170, 41',
		'accent-plus': '0, 0, 0',
	},
});
