import PropTypes from 'prop-types';
import { imaxIcon, vipIcon } from 'app/constants/IconsConstants';
import { KinositeSections } from 'app/types/common';
import { IMenu } from 'app/types/common';
import parse from 'html-react-parser';

export const getKinositeSectionName = (key: KinositeSections) => {
	const fallbackMap: Record<KinositeSections, string | JSX.Element> = {
		[KinositeSections.AFISHA]: i18n.t('Afisha'),
		[KinositeSections.NEWS]: i18n.t('News'),
		[KinositeSections.ABOUT_CINEMA]: i18n.t('Cinema'),
		[KinositeSections.NOT_MOVIES]: i18n.t('NotAMovie'),
		[KinositeSections.SEANCES]: i18n.t('Seances'),
		[KinositeSections.PUSHKIN_CARD]: i18n.t('PushkinCard'),
		[KinositeSections.CINEMAS]: i18n.t('Cinemas'),
		[KinositeSections.CONTACTS]: i18n.t('Contacts'),
		[KinositeSections.GOSUSLUGI]: i18n.t('Gosuslugi'),
		[KinositeSections.PROMO]: i18n.t('PromosAndDiscounts'),
		[KinositeSections.SOON]: i18n.t('SoonAtCinemas'),
		[KinositeSections.HALLS]: i18n.t('HallsInfo'),
		[KinositeSections.VIP]: vipIcon,
		[KinositeSections.IMAX]: imaxIcon,
	}

	const customTitle = window.kinosite.customization?.sectionTitles?.()[key];

	return customTitle ? parse(customTitle as string) : fallbackMap[key];
};

export const getKinositeSectionLink = (section: KinositeSections): IMenu => ({
	title: getKinositeSectionName(section),
	href: section
});

export const MENU_LINK_DEFAULT_SORT_ORDER = 1000;

export const getAppMenuLinks = () => {
	const customMenuLinks = window.kinosite.menuLinks?.() || [];

	const mappedCustomMenuLinks = customMenuLinks.map((linkObject) => (
		{
			...linkObject,
			isCustomCinemaLink: true
		}
	));

	return [
		getKinositeSectionLink(KinositeSections.AFISHA),
		getKinositeSectionLink(KinositeSections.SEANCES),
		...mappedCustomMenuLinks
	] as IMenu[];
};

export const getVipLinkMonitor: () => IMenu = () => ({
	href: '/vip',
	title: vipIcon,
	isCustomCinemaLink: true,
	sub: [
		{
			href: '/vip/afisha',
			title: i18n.t('Afisha')
		},
		{
			href: '/vip/news',
			title: i18n.t('Events')
		}
	]
});

export const getImaxLinkMonitor: () => IMenu = () => ({
	href: '/imax',
	title: imaxIcon,
	isCustomCinemaLink: true,
	sub: [
		{
			href: '/imax/afisha',
			title: i18n.t('Afisha')
		},
		{
			href: '/imax/news',
			title: i18n.t('Events')
		}
	]
});

export const AdvertisementsPropType = PropTypes.arrayOf(
	PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.string,
		original: PropTypes.string,
		bulletClass: PropTypes.string,
		url: PropTypes.string,
		originalClass: PropTypes.string,
		advertiser: PropTypes.string
	}),
);

export const CinemaPropType = PropTypes.shape<any>({
	address: PropTypes.string,
	app: PropTypes.object,
	modules: {
		articles_enabled: PropTypes.bool,
		branding_enabled: PropTypes.bool
	},
	city: PropTypes.string,
	details: PropTypes.bool,
	facebook: PropTypes.string,
	id: PropTypes.number,
	instagram: PropTypes.string,
	maps: PropTypes.string,
	odnoklassniki: PropTypes.string,
	payment: PropTypes.object,
	phoneNumbers: PropTypes.arrayOf(PropTypes.string),
	place: PropTypes.string,
	saleRules: PropTypes.bool,
	selectButton: PropTypes.object,
	soonPageIsEnabled: PropTypes.bool,
	title: PropTypes.string,
	twitter: PropTypes.string,
	vk: PropTypes.string,
	workTime: PropTypes.object,
	youtube: PropTypes.string,
	coordinates: PropTypes.string
});

export const AddressesPropTypes = PropTypes.shape({
	main: PropTypes.string,
	mapsLink: PropTypes.string,
	place: PropTypes.string
});

export const AppsPropTypes = PropTypes.shape({
	apple: PropTypes.string,
	google: PropTypes.string
});

export const SocialsPropTypes = PropTypes.shape({
	facebook: PropTypes.string,
	instagram: PropTypes.string,
	ok: PropTypes.string,
	twitter: PropTypes.string,
	vk: PropTypes.string,
	youtube: PropTypes.string
});

export const PhonesPropTypes = PropTypes.arrayOf(PropTypes.shape({
	name: PropTypes.string,
	value: PropTypes.string,
	comment: PropTypes.string
}));

export const ContactsPropType = PropTypes.shape({
	addresses: AddressesPropTypes,
	apps: AppsPropTypes,
	phones: PhonesPropTypes,
	socials: SocialsPropTypes
});

export const ReleasePropType = PropTypes.shape({
	id: PropTypes.number,
	rambler_id: PropTypes.number,
	title: PropTypes.string,
	age_rating: PropTypes.string,
	formats: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	})),
	genres: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	})),
	countries: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	})),
	cast: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	})),
	directors: PropTypes.arrayOf(PropTypes.string),
	trailer: PropTypes.shape({
		preview: PropTypes.string,
		thumbnail: PropTypes.string,
		hd: PropTypes.string
	}),
	rating: PropTypes.shape({
		kinopoisk: PropTypes.number,
		imdb: PropTypes.number
	}),
	description: PropTypes.string,
	year: PropTypes.number,
	poster: PropTypes.string,
	thumbnail: PropTypes.string,
	duration: PropTypes.number,
	qualifiers: PropTypes.arrayOf(PropTypes.string),
	russia_start_date: PropTypes.string,
	cinema_start_date: PropTypes.string
});

export const currentYear = new Date().getFullYear();

export const MONITOR_SUPPORT_EMAIL = 'sp@kinomonitor.ru';
export const KINOMIR_SUPPORT_EMAIL = 'support@kino-mir.ru';

export const SUPPORT_EMAIL = 'tickets@kinoplan.ru';
export const SUPPORT_PHONE = '8 495 980-17-27';

export const COUNT_NEWS_PAGE = 10;
export const COUNT_OTHER_NEWS_PAGE = 4;

export const SET_IS_MOBILE = 'SET_IS_MOBILE' as const;

export const MOBILE_MAX_SIZE = 767;
export const TABLET_MAX_SIZE = 991;

export const MOBILE_POSTERS_AMOUNT = 9;
export const DESKTOP_POSTERS_AMOUNT = 12;

export const CUSTOM_MARKER_OFFSET = [-24, -60];
export const DEFAULT_MARKER_OFFSET = [-16, -16];
export const DEFAULT_MAP_ZOOM = 16;

export const MENU_HEIGHT = 48;

export const ENTER_KEY_CODE = 13;

export const SHOW_POPUP = 'SHOW_POPUP' as const;
export const HIDE_POPUP = 'HIDE_POPUP' as const;

export const GET_APP_DATA_REQUEST = 'GET_APP_DATA_REQUEST' as const;
export const GET_APP_DATA_SUCCESS = 'GET_APP_DATA_SUCCESS' as const;
export const SET_APP_ERROR = 'SET_APP_ERROR' as const;

export const SET_ADVERTISEMENTS = 'SET_ADVERTISEMENTS' as const;
export const RESET_ADVERTISEMENTS = 'RESET_ADVERTISEMENTS' as const;

export const SET_DAILY_SEANCES = 'SET_DAILY_SEANCES' as const;
export const SET_DAILY_SEANCES_IS_FETCHING = 'SET_DAILY_SEANCES_IS_FETCHING' as const;
export const GET_DAILY_SEANCES_FAIL = 'GET_DAILY_SEANCES_FAIL' as const;
export const SET_DAILY_SEANCES_ERROR = 'SET_DAILY_SEANCES_ERROR' as const;

export const GET_AFISHA_REQUEST = 'GET_AFISHA_REQUEST' as const;
export const GET_AFISHA_SUCCESS = 'GET_AFISHA_SUCCESS' as const;
export const GET_AFISHA_INFO_REQUEST = 'GET_AFISHA_INFO_REQUEST' as const;
export const GET_AFISHA_INFO_SUCCESS = 'GET_AFISHA_INFO_SUCCESS' as const;
export const SET_AFISHA_ERROR = 'SET_AFISHA_ERROR' as const;
export const SET_SEANCE_DISPLAY_SETTINGS = 'SET_SEANCE_DISPLAY_SETTINGS' as const;
export const SET_QUALIFIERS = 'SET_QUALIFIERS' as const;

export const SET_CONTACTS = 'SET_CONTACTS' as const;
export const RESET_CURRENT_CINEMA_INFO = 'RESET_CURRENT_CINEMA_INFO' as const;
export const SET_ABOUT_CINEMA = 'SET_ABOUT_CINEMA' as const;
export const SET_SEVERAL_CINEMAS_ABOUT_DATA = 'SET_SEVERAL_CINEMAS_ABOUT_DATA' as const;

export const SET_HALLS = 'SET_HALLS' as const;

export const GET_RELEASES_SOON_REQUEST = 'GET_RELEASES_SOON_REQUEST' as const;
export const GET_RELEASES_SOON_SUCCESS = 'GET_RELEASES_SOON_SUCCESS' as const;
export const GET_RELEASES_SOON_FAIL = 'GET_RELEASES_SOON_FAIL' as const;

export const GET_RELEASE_REQUEST = 'GET_RELEASE_REQUEST' as const;
export const GET_RELEASE_SUCCESS = 'GET_RELEASE_SUCCESS' as const;
export const SET_RELEASE_ERROR = 'SET_RELEASE_ERROR' as const;
export const RESET_RELEASE_ITEM_STATE = 'RESET_RELEASE_ITEM_STATE' as const;

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST' as const;
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS' as const;
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL' as const;
export const RESET_NEWS = 'RESET_NEWS' as const;

export const GET_NEWS_ITEM_REQUEST = 'GET_NEWS_ITEM_REQUEST' as const;
export const GET_NEWS_ITEM_SUCCESS = 'GET_NEWS_ITEM_SUCCESS' as const;
export const GET_NEWS_ITEM_FAIL = 'GET_NEWS_ITEM_FAIL' as const;

export const SET_SELECTED_DAY = 'SET_SELECTED_DAY' as const;
export const CLOSE_SMART_BANNER = 'CLOSE_SMART_BANNER' as const;
export const SET_SEMIBLIND = 'SET_SEMIBLIND' as const;
export const SET_FONT_SIZE = 'SET_FONT_SIZE' as const;

export const SET_NEW_CURRENT_CINEMA_DATA = 'SET_NEW_CURRENT_CINEMA_DATA' as const;
export const SET_CITY_FILTER = 'SET_CITY_FILTER' as const;
export const SET_CURRENT_CITY_ID = 'SET_CURRENT_CITY_ID' as const;
export const SET_IS_USER_AUTH = 'SET_IS_USER_AUTH' as const;
export const SET_IS_WIDGET_IN_KIOSK_MODE = 'SET_IS_WIDGET_IN_KIOSK_MODE' as const;
export const SET_COUNT_UNREAD_NOTIFICATIONS = 'SET_COUNT_UNREAD_NOTIFICATIONS' as const;

export const INTERNAL_SERVICE_ERROR = 201;

export const LIMIT_ORDER_NUMBER = 8;
export const REFUND_LINK = 'https://kinokassa.ru';

export const __DEV__ = process.env.NODE_ENV !== 'production';

export const BRIGHTNESS_MIDDLE_VALUE = 125;

export const isEnabledSalesRules = !!window.kinosite?.additionalModules?.saleRulesPage ?? false;
export const isEnabledDetails = !!window.kinosite?.additionalModules?.detailsPage ?? false;
export const gosuslugiOnAfishaData = window.kinosite?.additionalModules?.gosuslugiOnAfisha;
export const USER_LANGUAGE = navigator.language.trim().split(/-|_/)[0];
export enum  SUPPORTED_LANGS {
	RU = "ru",
	KK = "kk"
}
export const FALLBACK_LANG = SUPPORTED_LANGS.RU;
export const SELECTED_LANGUAGE = localStorage.getItem('lang') || USER_LANGUAGE || FALLBACK_LANG;
