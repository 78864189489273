import { Redirect, Route, Switch } from 'react-router-dom';
import React, { lazy, useMemo } from 'react';
import MonitorImaxPageComponent from 'app/components/Monitor/MonitorImaxPageComponent';
import MonitorVipPageComponent from 'app/components/Monitor/MonitorVipPageComponent';
import { loadComponent } from 'app/selectors/Helpers';
import { AppComponentProps } from 'app/containers/AppContainer';
import AppRedirectPage from 'app/components/footer/ApplicationRedirectPage';
import { REFUND_LINK } from 'app/constants/AppConstants';
import ReleasePage from 'app/containers/ReleasePageContainer';
import { useSelector } from 'react-redux';
import { getIsAllPromosEnabled, getRoutePrefix, getSectionDisplaySettingsForCity, getSoonPageIsEnabled, hasAboutCinema } from 'app/selectors/AppSelectors';
import { getCustomPagesRoutes } from 'app/selectors/MenuSelectors';

const Afisha = loadComponent(lazy(() => import('app/components/afisha/AfishaComponent')));
const AboutCinema = loadComponent(lazy(() => import('app/containers/CinemaContainer')));
const DailySeances = loadComponent(lazy(() => import('app/components/DailySeancesComponent')));
const News = loadComponent(lazy(() => import('app/containers/NewsContainer')));
const NewsItem = loadComponent(lazy(() => import('app/containers/NewsItemContainer')));
const Promo = loadComponent(lazy(() => import('app/containers/PromoContainer')));
const PromoItemContainer = loadComponent(lazy(() => import('app/containers/PromoItemContainer')));
const Soon = loadComponent(lazy(() => import('app/containers/ReleasesSoonPageContainer')));
const CustomPage = loadComponent(lazy(() => import('app/containers/CustomPageContainer')));
const CityCinemas = loadComponent(lazy(() => import('app/containers/CityCinemas/CityCinemasContainer')));
const Contacts = loadComponent(lazy(() => import('app/containers/ContactsContainer')));
const GosuslugiComponent = loadComponent(lazy(() => import('app/components/additionalModules/GosuslugiComponent')));
const StaticAdditionalPageComponent = loadComponent(
	lazy(() => import('app/components/additionalModules/StaticAdditionalPageComponent'))
);

export const AppRoutes = (props: Partial<AppComponentProps>) => {
	const addedPath = useSelector(getRoutePrefix);

	return addedPath
		? (
			<Switch>
				<Route
					path="/:cityId"
					render={() => (<Routes {...props} />)}
				/>
				<Redirect from="/" to={`/${props.cityId}`} />
			</Switch>
		)
		: <Routes {...props} />;
};

const Routes = ({
	cinema,
	isSeveralCinemasInCity,
	isKinokassa
}: Partial<AppComponentProps>) => {
	const isAboutCinema = useSelector(hasAboutCinema);
	const soonPageIsEnabled = useSelector(getSoonPageIsEnabled);
	const isAllPromosEnabled = useSelector(getIsAllPromosEnabled);
	const { imax, vip, pro_culture, not_movie } = useSelector(getSectionDisplaySettingsForCity);
	const customPagesRoutes = useSelector(getCustomPagesRoutes);
	const addedPath = useSelector(getRoutePrefix);

	const promosEnabled = isAllPromosEnabled || (cinema?.modules && cinema.modules.promotions_enabled);

	const cinemaRoutes = useMemo(() => (
		window.kinosite.routes?.map((config, key) => {
			return <Route {...config} key={key} />
		}) || []
	), []);

	return (
		<Switch>
			{cinema?.isKinokassa ?
				<Route path={`${REFUND_LINK}/redirect_to_store`} render={() => <AppRedirectPage appLinks={cinema?.app} />} />
				: null}
			<Route path={`${addedPath}/release/:id/:modifier?`} component={!isKinokassa ? ReleasePage.Main : ReleasePage.Kinokassa} />
			<Route path={`${addedPath}/seances`} render={() => <DailySeances page="seances"/>} />
			{pro_culture ? (
				<Route
					path={`${addedPath}/pushkin_card`}
					render={() => <Afisha isProCulture />}
				/>
			) : null}
			{not_movie ? (
				<Route
					path={`${addedPath}/not_movies`}
					render={() => <Afisha isNotMovie />}
				/>
			) : null}
			{soonPageIsEnabled ?
				<Route exact path={`${addedPath}/soon`} component={Soon} />
				: null}
			{promosEnabled ?
				<Route
					exact
					path={`${addedPath}/promo`}
					render={(routeProps) => <Promo {...routeProps} page="promo" />}
				/>
				: null}
			{promosEnabled ?
				<Route
					exact
					path={`${addedPath}/promo/:id`}
					render={(routeProps) => <PromoItemContainer {...routeProps} page="promo-item" />}
				/>
				: null}
			{cinema?.modules && cinema.modules.articles_enabled ?
				<Route
					path={`${addedPath}/news`}
					exact
					render={(routeProps) => <News {...routeProps} page="news" />}
				/>
				: null}
			{cinema?.modules && cinema.modules.articles_enabled ?
				<Route
					path={`${addedPath}/news/:id`}
					render={(routeProps) => <NewsItem {...routeProps} page="news-item" />}
				/>
				: null}
			{isAboutCinema ?
				<Route path={`${addedPath}/cinema`} component={AboutCinema} />
				: null}
			{isSeveralCinemasInCity
				? <Route path={`${addedPath}/cinemas`} component={CityCinemas} />
				: null
			}
			{!isSeveralCinemasInCity
				? <Route path={`${addedPath}/contacts`} component={Contacts} />
				: null
			}
			{imax
				? <Route
					path={addedPath ? `${addedPath}/imax` : '/imax'}
					render={() => <MonitorImaxPageComponent addedPath={addedPath} />}
				/>
				: null
			}
			{vip
				? <Route
					path={addedPath ? `${addedPath}/vip` : '/vip'}
					render={() => <MonitorVipPageComponent addedPath={addedPath} />}
				/>
				: null
			}
			{customPagesRoutes.length
				? customPagesRoutes.map(page =>
					<Route
						path={addedPath + page.href}
						key={page.id}
						render={() => <CustomPage pageId={page.id} />}
					/>,
				)
				: null
			}
			{
				window.kinosite.additionalModules?.gosuslugiPage && (
					<Route path="/gosuslugi" component={GosuslugiComponent}/>
				)
			}
			{
				window.kinosite.additionalModules?.detailsPage && (
					<Route
						path="/details"
						render={
							() => (
								<StaticAdditionalPageComponent
									key="detailsPage"
									getData={window.kinosite.additionalModules?.detailsPage?.getData as any}
									title={i18n.t('Requisites')}
								/>
							)
						}/>
				)
			}
			{
				window.kinosite.additionalModules?.saleRulesPage && (
					<Route
						path="/sale-rules"
						render={
							() => (
								<StaticAdditionalPageComponent
									key="saleRulesPage"
									getData={window.kinosite.additionalModules?.saleRulesPage?.getData as any}
									title={i18n.t('PaymentTerms')}
								/>
							)
						}/>
				)
			}
			{...cinemaRoutes}
			<Route component={Afisha} />
		</Switch>
	);
};
