// hardcoded cinema IDs
export const NEVELSK_CINEMA_ID = 4180;
export const KINOGRADVIDNOE_CINEMA_ID = 7424;
export const VREMYAKINO_CINEMA_ID = 8015;
export const MONITOR_CITY_CINEMA_ID = 111;
export const MONITOR_SBS_CINEMA_ID = 112;
export const MONITOR_KINOSTAR_CINEMA_ID = 1615;
export const CINEMAHALL_CINEMA_ID = 2366;
export const GORIZONT_CINEMA_ID = 6440;
export const AVRORA_CINEMA_ID = 70;
export const KINOKHV_CINEMA_IDS = [8077, 1464, 1161, 298, 299];
export const TAMAN_CINEMA_ID = 1049;
export const PREMIERE_KUZHENER_CINEMA_ID = 9176;
export const POBEDA_KURGANINSK_CINEMA_ID = 1219;
export const COSMOS_KANEVSKAYA_CINEMA_ID = 1054;
export const FORUM_VISELKI_CINEMA_ID = 2859;
export const TIMASHEVSK_ZARYA_CINEMA_ID = 1048;

export const REDFILM_CINEMA_IDS = [222, 771];
export const ARMAN3D_MART_TARAZ_CINEMA_ID = 7253;
export const ARMAN3D_MART_KOSTANAY_CINEMA_ID = 1109;
export const ARMAN3D_CINEMA_IDS = [165, 174, ARMAN3D_MART_KOSTANAY_CINEMA_ID, 1452, 2674, ARMAN3D_MART_TARAZ_CINEMA_ID];
export const MONITOR_CINEMA_IDS = [
	95, MONITOR_CITY_CINEMA_ID, MONITOR_SBS_CINEMA_ID, 113, 127, 815, 1171,
	1472, 1589, 1801, 1025, 1558, 97, MONITOR_KINOSTAR_CINEMA_ID, 763, 884, 9315
];
export const MAXICINEMA_ID = 8644;
export const LUMIERE_ID = 6407;
export const ZODIAC_CINEMA_ID = 4154;
export const MAGIC_CINEMA_ID = 8211;
export const PRIME_CINEMA_ID = 8680;
export const ATLAS_CINEMA_ID = 7696;
export const ARSENAL_CINEMA_ID = 717;
export const CHRISTY_CINEMA_ID = 7907;
export const CINEMAX_DOSTIK_CINEMA_ID = 1867;
export const CINEMAX_SHIMKENT_CINEMA_ID = 2921;

export const CHAPLIN_ADK_CINEMA_ID = 1094;
export const CHAPLIN_MEGA_PARK_CINEMA_ID = 1879;
export const CHAPLIN_MEGA_ALMA_ATA_CINEMA_ID = 7259;
export const CHAPLIN_KHAN_SHATYR_CINEMA_ID = 450;
export const CHAPLIN_MEGA_SILK_WAY_CINEMA_ID = 3109;

export const CHAPLIN_IDS = [
	CHAPLIN_ADK_CINEMA_ID,
	CHAPLIN_MEGA_PARK_CINEMA_ID,
	CHAPLIN_MEGA_ALMA_ATA_CINEMA_ID,
	CHAPLIN_KHAN_SHATYR_CINEMA_ID,
	CHAPLIN_MEGA_SILK_WAY_CINEMA_ID
];

export const SOCHI_CINEMA_ID = 149;
export const KINOGRAD_VP_CINEMA_ID = 766;

// hardcoded city IDs:
export const KRASNODAR_CITY_ID = 26;
