import React from 'react';
import { closeIcon } from 'app/constants/IconsConstants';
import { ClickOnKeyPressWrap, isEscPress } from 'app/utils/KeyboardHelpers';
import { PopupComponentProps } from 'app/containers/PopupContainer';
import classNames from 'classnames';
import { useMobileViewBreakPoint } from 'app/hooks';
import { TABLET_MAX_SIZE } from 'app/constants/AppConstants';

const PopupComponent = ({
	hidePopup,
	children,
	popupContentNode,
	withCloseButton,
	mobileTitle,
	className,
	mobileViewBreakpoint = TABLET_MAX_SIZE,
	onClosePopup,
} : PopupComponentProps) => {
	const { isMobileView } = useMobileViewBreakPoint(mobileViewBreakpoint);

	const handleClose = () => {
		hidePopup();
		onClosePopup?.();
	}

	if (!popupContentNode) return null;

	const hasNeedShowMobileHeader = Boolean(mobileTitle || withCloseButton);

	return (
		<div className={classNames("popup-container", {
			[`${className}`]: className
		})}>
			<ClickOnKeyPressWrap clickCondition={isEscPress}>
				<div className="popup-overlay" onClick={handleClose}>
					{withCloseButton && !isMobileView &&
						<div className="popup__exit">
							{closeIcon}
						</div>
					}
				</div>
			</ClickOnKeyPressWrap>
			{
				isMobileView ? (
					<div
						className="mobile-popup"
					>
						{
							hasNeedShowMobileHeader && (
								<div className="mobile-popup__header">
									{mobileTitle && <span className="mobile-popup__title">{mobileTitle}</span>}
									{withCloseButton && (
										<div className="mobile-popup__exit" onClick={handleClose}>
											{closeIcon}
										</div>
									)}
								</div>
							)
						}
						{popupContentNode || children}
					</div>
				) : (
					<div className="popup">
						{popupContentNode ||
							<div className="popup__content">
								<div className="popup__content__body">
									{children}
								</div>
							</div>
						}
					</div>
				)
			}
		</div>
	);
}

export default PopupComponent;
