import { useSelector } from "react-redux";
import { RAMBLER_WIDGET_KEY } from "app/constants/SettingsConstants";
import { REFUND_LINK } from "app/constants/AppConstants";
import { getCinema, getHasNeedHideRefundButton, getIsKinokassa } from "app/selectors/AppSelectors";
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';

interface IProps {
	classNamesOfContainers?: string[];
	linkClassName: string;
}

const RefundButton = ({linkClassName, classNamesOfContainers = []} : IProps) => {
	const cinemaID = useSelector(getCinema).id;
	const isKinokassa = useSelector(getIsKinokassa);
	const hasNeedHideRefundButton = useSelector(getHasNeedHideRefundButton);

	if (RAMBLER_WIDGET_KEY || hasNeedHideRefundButton) return null;

	const link = (
		<KeydownEnterLink
			type="plain"
			className={linkClassName}
			href={`${REFUND_LINK}/refund${cinemaID && !isKinokassa ? `?cinemaID=${cinemaID}` : ''}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			{i18n.t('IDontReceiveTicket')}
		</KeydownEnterLink>
	);

	return classNamesOfContainers.reduceRight((
		node, currentClassName
	) => (React.createElement("div", {className: currentClassName}, node)), link);
}

export default RefundButton;