import { useEffect, useState } from "react"
import { CinemaLawDocumentsProps } from "./LawDocumentsCheckboxContainer";
import classNames from 'classnames';
import { ILawDocumentLink, getCinemaDocumentsLinks } from "./helpers";
import { LawDocumentsPopup } from "./LawDocumentsPopup";
import * as styles from "css/LawDocuments.module.scss";

const LawDocumentsCheckbox = ({
	cinemaID, classes, showPopup,
	name, isChecked, onChange
}: CinemaLawDocumentsProps) => {
	const [lawDocsLinks, setLawDocsMap] = useState<ILawDocumentLink[] | null>(null);

	useEffect(() => {
		setLawDocsMap(getCinemaDocumentsLinks(cinemaID))
	}, [cinemaID]);

	const handleLinkClick = (link: ILawDocumentLink) => {
		showPopup({
			popupContentNode: <LawDocumentsPopup lawDocsLinks={lawDocsLinks} initialActiveLink={link}/>,
			withCloseButton: true,
		});
	}

	return lawDocsLinks && (
		<div className={classNames(`${styles.lawDocuments}`, {
			[`${classes}`]: classes
		})}>
			<input
				type="checkbox"
				className={`${styles.lawDocumentsCheckbox}`}
				checked={isChecked}
				name={name}
				onChange={onChange}
			/>
			<div>
				{`${i18n.t("YouAgreeTo.base")} `}
				{lawDocsLinks.map((link, index) => {
					return link.docLinkTitle && (
						<>
							{
								index === lawDocsLinks.length - 1 && lawDocsLinks.length > 1
									? ` ${i18n.t("and")} `
									: index !== 0 ? ', ' : null
							}
							{
								link.docLinkCustomRenderFunction?.(link.docLinkTitle, link.title) || (
									<span
										key={link.docLinkTitle}
										onClick={() => handleLinkClick(link)}
										className={`${styles.lawDocumentsLink}`}
									>
										{link.docLinkTitle}
									</span>
								)
							}
						</>
					);
				})}
			</div>
		</div>
	)
}

export {LawDocumentsCheckbox}
