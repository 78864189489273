import React from 'react';
import classNames from 'classnames'; 
import CitiesListSelectComponent from 'app/components/CitySelect/CitiesListSelectComponent';
interface IProps {
	className?: string;
	isOpen: boolean;
}

const CitiesListSelectPopup = ({className = '', isOpen}: IProps) => (
	<div className={classNames('popup__content', className, {'popup__content--open': isOpen})}>
		<CitiesListSelectComponent />
	</div>
);

export default CitiesListSelectPopup;
