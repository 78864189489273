export default {
	cinema: {
		address: 'ул. Заозерная, 15',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/1maykino',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/кинотеатр-первомайский/id1333485547?l=ru&ls=1&mt=8',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.pervomaysk',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBaXyOR9GC',
		selectButton: {
			options: [
				{
					value: 'https://1maykino.ru',
					title: 'Первомайский',
				},
				{
					value: 'https://slavakino.ru',
					title: 'Слава',
				},
			],
			selectedOptionValue: 'https://1maykino.ru',
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
