import { CinemaLawDocumentsProps } from "./LawDocumentsCheckboxContainer";
import { ILawDocumentLink } from "./helpers";
import { LawDocumentsPopup } from "./LawDocumentsPopup";

type Props = Pick<CinemaLawDocumentsProps, 'classes' | 'showPopup'> & {lawDocsLinks: ILawDocumentLink[] | null};

const LawDocumentsLinks = ({classes, showPopup, lawDocsLinks}: Props) => {
	const handleLinkClick = (link: ILawDocumentLink) => {
		showPopup({
			popupContentNode: <LawDocumentsPopup lawDocsLinks={lawDocsLinks} initialActiveLink={link}/>,
			withCloseButton: true,
		});
	}

	return (
		<>
			{lawDocsLinks && lawDocsLinks.map((link, index) => {
				return link.docLinkTitle && (
					<>
						{
							index === lawDocsLinks.length - 1 && lawDocsLinks.length > 1
								? ` ${i18n.t("and")} `
								: index !== 0 ? ', ' : null
						}
						{
							link.docLinkCustomRenderFunction?.(link.docLinkTitle, link.title, classes) || (
								<span
									key={link.docLinkTitle}
									onClick={() => handleLinkClick(link)}
									className={classes}
								>
									{link.docLinkTitle}
								</span>
							)
						}
					</>
				);
			})}
		</>
	);
}

export default LawDocumentsLinks;